import { useState, useEffect, useRef } from 'react';

import { SearchResultSuggestion } from './types/SearchResultSuggestion';

import useDebounce from './useDebounce';

type AppProps = {
    searchAction: string,
    searchPageId?: string,
    searchSuggestAction: string,
    placeholderText: string
    searchType: string,
    deptSearchAction?: string,
    deptSearchPageId?: string,
    deptName?: string,
    superSearchLabel?: string
};

const App: React.FC<AppProps> = ({searchAction, searchPageId, searchSuggestAction, placeholderText, searchType, deptSearchAction, deptSearchPageId, deptName, superSearchLabel}) => {
    const [debouncedTerms, terms, setTerms] = useDebounce('', 500);
    const [searchSuggestions, setSearchSuggestions] = useState<SearchResultSuggestion[]>();
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [suggestionsVisible, setSuggestionsVisible] = useState(true);
    const searchInputContainerRef = useRef<HTMLFormElement | null>(null);
    const termsInputRef = useRef<HTMLInputElement | null>(null);
    const searchSubmitRef = useRef<HTMLButtonElement | null>(null);
    const firstSuggestionRef = useRef<HTMLAnchorElement | null>(null);
    const lastSuggestionRef = useRef<HTMLAnchorElement | null>(null);
    const [currentAction, setCurrentAction] = useState<string>(deptSearchAction ?? searchAction);
    const [currentSearchPageId, setCurrentSearchPageId] = useState<string | undefined>(deptSearchPageId ?? searchPageId);

    useEffect(() => {
        const processSuggestions = async () => {
            if (debouncedTerms.length > 2) {
                try {                    
                    const response = await fetch(`${searchSuggestAction}?terms=${debouncedTerms}&pageId=${currentSearchPageId}`);
                    if (response && response.status === 200) {
                        const responseData = await response.json();
                        if (responseData) {
                            setSearchSuggestions(responseData);
                        }
                    }
                } catch (err) {
                    setSearchSuggestions(undefined);
                }
            } else {
                setSearchSuggestions(undefined);
            }
        };

        processSuggestions();
    }, [searchSuggestAction, debouncedTerms, currentSearchPageId, setSearchSuggestions]);

    const handleInputKeydown: React.KeyboardEventHandler<HTMLInputElement> = event => {        
        if (suggestionsVisible && searchSuggestions !== undefined && searchSuggestions.length > 0 && event.key === 'ArrowDown') {
            event.preventDefault();
            firstSuggestionRef.current?.focus();
        } else if (suggestionsVisible && searchSuggestions !== undefined && searchSuggestions.length > 0 && event.key === 'ArrowUp') {
            event.preventDefault();
            lastSuggestionRef.current ? lastSuggestionRef.current.focus() : firstSuggestionRef.current?.focus();
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setSuggestionsVisible(false);
            setSelectedIndex(null);
        } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            setSuggestionsVisible(true);
        } else if (event.key !== "Enter") {            
            setSelectedIndex(null);

            if (event.key !== "Tab" && event.key !== "Shift" &&
                event.key !== "ArrowLeft" && event.key !== "ArrowRight" &&
                event.key !== "Home" && event.key !== "End") {
                setSuggestionsVisible(true);
                setSearchSuggestions(undefined);
            }
        }
    };    

    const handleSuggestionBlur: React.FocusEventHandler<HTMLFormElement> = () => {
        setTimeout(() => {
            if (!searchInputContainerRef.current?.contains(document.activeElement)) {
                setSelectedIndex(null);
                setSuggestionsVisible(false);
            } else if (searchSubmitRef.current?.contains(document.activeElement) || termsInputRef.current?.contains(document.activeElement)) {
                setSelectedIndex(null);
            }
        }, 1);
    };

    const handleSuggestionKeydown: React.KeyboardEventHandler<HTMLAnchorElement> = event => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            const parentElement = (event.target as HTMLElement).parentElement;

            if (parentElement?.nextElementSibling?.firstElementChild) {
                (parentElement.nextElementSibling.firstElementChild as HTMLElement).focus();
            } else {                
                firstSuggestionRef.current?.focus();
            }
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            const parentElement = (event.target as HTMLElement).parentElement;

            if (parentElement?.previousElementSibling?.firstElementChild) {
                (parentElement.previousElementSibling.firstElementChild as HTMLElement).focus();
            } else {
                lastSuggestionRef.current?.focus();
            }
        } else if (event.key === "Home") {
            event.preventDefault();
            firstSuggestionRef.current?.focus();
        } else if (event.key === "End") {
            event.preventDefault();
            lastSuggestionRef.current?.focus();
        } else if (event.key === "Escape") {
            event.preventDefault();
            termsInputRef.current?.focus();
            setSuggestionsVisible(false);
        } else if (event.key !== "Enter" && event.key !== "Shift" && event.key !== "Tab") {
            termsInputRef.current?.focus();

            if (event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
                setSearchSuggestions(undefined);
            }
        }
    };

    const renderSuggestions = () => { 
        if (searchSuggestions !== undefined && searchSuggestions.length > 0) {
            return searchSuggestions.map((suggestion, index) => 
                <li
                    role="option"
                    aria-selected={index === selectedIndex}
                    onMouseEnter={() => setSelectedIndex(index)}
                    onMouseLeave={() => setSelectedIndex(null)}
                >
                    <a 
                        href={suggestion.Url} 
                        className={suggestion.ResultType === 'document' ? 'document-suggestion' : 'page-suggestion'} 
                        onKeyDown={event => handleSuggestionKeydown(event)}
                        ref={index === 0 ? firstSuggestionRef : index === searchSuggestions.length - 1 ? lastSuggestionRef : null}
                        onFocus={() => setSelectedIndex(index)}
                    >
                        <div>
                            <span className="suggest-title">{suggestion.Title}</span>
                            {suggestion.ResultType === 'document' && suggestion.Extension !== '' &&
                                <span className="meta"><em>({suggestion.Extension})</em></span>
                            }
                        </div>
                        <span className="suggest-path">{suggestion.Path}</span>
                    </a>
                </li>
            );
        } else {
            return <li className="no-results">No Results Found</li>
        }
    };

    const handleChangeSearchAction = (searchType: string) => {
        if (searchType === 'department') {
            setCurrentAction(deptSearchAction ?? searchAction);
            setCurrentSearchPageId(deptSearchPageId ?? searchPageId);
        } else {
            setCurrentAction(searchAction);
            setCurrentSearchPageId(searchPageId);
        }
    };

    return (
        <>
            <form 
                className={`search search-component${searchType === "homepage" ? ` search-lg` : ''}${suggestionsVisible && searchSuggestions !== undefined ? ' suggestions-active' : ''}`}
                action={currentAction}
                onBlur={(event) => handleSuggestionBlur(event)}
                ref={searchInputContainerRef}
            >
                <label htmlFor={`search-terms-${searchType}`}>
                    <span className="visually-hidden">Search</span>
                </label>
                <input 
                    id={`search-terms-${searchType}`}
                    type="search"
                    autoComplete="off"
                    placeholder={placeholderText}
                    value={terms}                
                    name="terms"
                    onChange={event => setTerms(event.target.value)}
                    onKeyDown={event => handleInputKeydown(event)}   
                    onFocus={() => setSuggestionsVisible(true)}
                    ref={termsInputRef}
                />
                <button 
                    type="submit"
                    aria-label="Submit Search"
                    onFocus={() => setSuggestionsVisible(true)}
                    ref={searchSubmitRef}
                >
                    <i className="fas fa-search"></i>
                </button>
                <div 
                    className={`search-suggest-container${suggestionsVisible && searchSuggestions !== undefined ? '' : ' hidden'}${selectedIndex !== null ? ' active' : ''}`}                        
                >
                    <ul className="search-suggest-list">
                        {renderSuggestions()}
                    </ul>
                </div>
            </form>
            {searchType === "banner" && deptSearchAction !== undefined &&
                <div className="radios">
                    <div className="search-select">
                        <input 
                            type="radio" 
                            className="dept-search-radio" 
                            id="search-select-1" 
                            name="search-select" 
                            checked={currentAction === deptSearchAction}
                            onChange={() => handleChangeSearchAction('department')}
                        />
                        <label htmlFor="search-select-1">{deptName}</label>
                    </div>
                    <div className="search-select">
                        <input 
                            type="radio" 
                            className="dept-search-radio" 
                            id="search-select-2" 
                            name="search-select" 
                            checked={currentAction === searchAction}
                            onChange={() => handleChangeSearchAction('site')}
                        />
                        <label htmlFor="search-select-2">{superSearchLabel}</label>
                    </div>
                </div>
            }
        </>
    );
};

export default App;
